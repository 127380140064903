import { AppButton, AppIcon } from '@launchpad/components';
import AppRadioInput from '@launchpad/components/elements/form/AppRadioInput';
import i18n from '@launchpad/i18n';
import { OverrideService } from '@launchpad/logic/services';
import React from 'react';
import { Trans } from 'react-i18next';

const CardLostStolenReason = ({ onBack, onNext }) => {
  const [reasonForReplace, setReasonForReplace] = React.useState('');

  return (
    <div style={{ flex: 1 }} className="d-flex flex-column">
      <h1 style={{ fontSize: 28, fontWeight: 'bold', paddingBottom: 20 }}>
        <Trans>Replace Card</Trans>
      </h1>
      <h1 style={{ fontSize: 16, paddingBottom: 20 }}>
        <Trans>Please provide the reason for the replacement card</Trans>
      </h1>
      <div
        className="d-flex flex-column justify-content-between py-4 w-75"
        style={{ gap: 10 }}
      >
        <AppRadioInput
          name="reasonForReplace"
          labelText={i18n.t('Lost')}
          onChange={() => setReasonForReplace('lost')}
          value="lost"
          checked={reasonForReplace === 'lost'}
          fontSizeLabel={18}
        />
        <AppRadioInput
          name="reasonForReplace"
          labelText={i18n.t('Stolen')}
          onChange={() => setReasonForReplace('stolen')}
          value="stolen"
          checked={reasonForReplace === 'stolen'}
          fontSizeLabel={18}
        />
        <AppRadioInput
          name="reasonForReplace"
          labelText={i18n.t('Damaged')}
          onChange={() => setReasonForReplace('damaged')}
          value="damaged"
          checked={reasonForReplace === 'damaged'}
          fontSizeLabel={18}
        />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: 30
        }}
      >
        <div
          onClick={() => onBack()}
          style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
        >
          <AppIcon
            name="fa.arrow-left"
            style={{
              color: OverrideService.getThisOrOverridenColors().main_color
            }}
          />
          <span
            style={{
              color: OverrideService.getThisOrOverridenColors().main_color,
              textDecorationLine: 'underline',
              marginRight: 10
            }}
          >
            <Trans>Back</Trans>
          </span>
        </div>
        {reasonForReplace && (
          <div
            onClick={() => onNext(reasonForReplace)}
            style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
          >
            <span
              style={{
                color: OverrideService.getThisOrOverridenColors().main_color,
                textDecorationLine: 'underline',
                marginRight: 10
              }}
            >
              <Trans>Next</Trans>
            </span>
            <AppIcon
              name="fa.arrow-right"
              style={{
                color: OverrideService.getThisOrOverridenColors().main_color
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default CardLostStolenReason;
