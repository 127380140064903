import { AppIcon, InputField } from '@launchpad/components';
import i18n from '@launchpad/i18n';
import { OverrideService } from '@launchpad/logic/services';
import AuthService from '@launchpad/logic/services/AuthService';
import LoadingHelper from '@launchpad/util/LoadingHelper';
import React from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import CardModel from '../../models/CardModel';

const CardLostStolenAddress = ({
  onBack,
  onNext,
  card,
  changeCardLockStatus,
  reasonForReplace
}) => {
  const { addressOne, addressTwo, city, country, postCode } = useSelector(
    (state: any) => state.auth?.profile || {}
  );
  const [addressConfirmation, setAddressConfirmation] = React.useState({
    addressOne,
    addressTwo,
    city,
    country: country.name,
    postCode
  });

  function handleInputChange(value, name) {
    setAddressConfirmation({
      ...addressConfirmation,
      [name]: value
    });
  }

  async function submitReplaceCard() {
    // first lock card if lost or stolen:
    if (
      (reasonForReplace === 'lost' || reasonForReplace === 'stolen') &&
      !!card.locked === false
    ) {
      await changeCardLockStatus(true);
    }

    const addressString =
      addressConfirmation && typeof addressConfirmation === 'object'
        ? Object.values(addressConfirmation)
            .filter(value => value !== '')
            .join(', ')
        : '';
    LoadingHelper.executeAction(
      () => {
        return new CardModel().replacePaymentDevice(
          card.id,
          reasonForReplace,
          addressString
        );
      },
      {
        successMessage: i18n.t('Your card has been successfully replaced'),
        loadingMessage: i18n.t('Replacing card...'),
        failureMessage: i18n.t('Already requested replacement for this card!'),
        manualClose: false,
        renderIcon: (state: string) => {
          if (state === 'loading') return null;
          return <AppIcon name="fa.credit-card" style={{ fontSize: 150 }} />;
        },
        beforeClose: () => {
          AuthService.refreshAuth();
          onNext();
        }
      }
    );
  }

  return (
    <div
      style={{ flex: 1 }}
      className="d-flex flex-column justify-content-between "
    >
      <h1 style={{ fontSize: 28, fontWeight: 'bold', paddingBottom: 20 }}>
        <Trans>Replace Card</Trans>
      </h1>
      <h1 style={{ fontSize: 16 }}>
        <Trans>Please provide the reason for the replacement card</Trans>
      </h1>
      <div
        className="d-flex flex-column align-items-center justify-content-between py-4"
        style={{ gap: 15 }}
      >
        <InputField
          className="input-group"
          materialProps={{
            fullWidth: true
          }}
          label={i18n.t('Address Line 1')}
          name="addressOne"
          type="text"
          placeholder={'Address Line 1'}
          InputLabelProps={{
            shrink: true
          }}
          value={addressConfirmation.addressOne}
          onInputText={(value, name) => handleInputChange(value, name)}
        />
        <InputField
          className="input-group"
          materialProps={{
            fullWidth: true
          }}
          label={i18n.t('Address Line 2')}
          name="addressTwo"
          type="text"
          placeholder={'Address Line 2'}
          InputLabelProps={{
            shrink: true
          }}
          value={addressConfirmation.addressTwo}
          onInputText={(value, name) => handleInputChange(value, name)}
        />
        <InputField
          className="input-group"
          materialProps={{
            fullWidth: true
          }}
          label={i18n.t('Post code')}
          name="postCode"
          type="text"
          placeholder={'Post code'}
          InputLabelProps={{
            shrink: true
          }}
          value={addressConfirmation.postCode}
          onInputText={(value, name) => handleInputChange(value, name)}
        />
        <InputField
          className="input-group"
          materialProps={{
            fullWidth: true
          }}
          label={i18n.t('City')}
          name="city"
          type="text"
          placeholder={'City'}
          InputLabelProps={{
            shrink: true
          }}
          value={addressConfirmation.city}
          onInputText={(value, name) => handleInputChange(value, name)}
        />
        <InputField
          className="input-group"
          materialProps={{
            fullWidth: true
          }}
          label={i18n.t('Country')}
          name="country"
          type="text"
          placeholder={'Country'}
          InputLabelProps={{
            shrink: true
          }}
          value={addressConfirmation.country}
          onInputText={(value, name) => handleInputChange(value, name)}
        />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: 30
        }}
      >
        <div
          onClick={() => onBack()}
          style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
        >
          <AppIcon
            name="fa.arrow-left"
            style={{
              color: OverrideService.getThisOrOverridenColors().main_color
            }}
          />
          <span
            style={{
              color: OverrideService.getThisOrOverridenColors().main_color,
              textDecorationLine: 'underline',
              marginRight: 10
            }}
          >
            <Trans>Back</Trans>
          </span>
        </div>
        <div
          onClick={() => {
            submitReplaceCard();
          }}
          style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
        >
          <span
            style={{
              color: OverrideService.getThisOrOverridenColors().main_color,
              textDecorationLine: 'underline',
              marginRight: 10
            }}
          >
            <Trans>Next</Trans>
          </span>
          <AppIcon
            name="fa.arrow-right"
            style={{
              color: OverrideService.getThisOrOverridenColors().main_color
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default CardLostStolenAddress;
