import React, { useEffect } from 'react';
import AuthService from '@launchpad/logic/services/AuthService';
import CardModel from '@launchpad/modules/card/models/CardModel';
import LoadingHelper from '@launchpad/util/LoadingHelper';
import { AppButton, AppIcon, SwitchButton, useSelector } from '@launchpad';
import { Trans } from 'react-i18next';
import i18n from '@launchpad/i18n';
import CardLostStolenModal from './card-lost-stolen-modal/CardLostStolenModal';

const LockCard = props => {
  const { addressOne } = useSelector((state: any) => state.auth?.profile || {});
  const [lostStolenModal, setLostStolenModal] = React.useState(false);
  const [
    showRequestReplacementCardContent,
    setShowRequestReplacementCardContent
  ] = React.useState(false);
  const [reasonForReplace, setReasonForReplace] = React.useState('');
  const [addressConfirmation, setAddressConfirmation] = React.useState(
    addressOne
  );
  const { card } = props;

  useEffect(() => {
    if (lostStolenModal) {
      setAddressConfirmation(addressOne);
      setReasonForReplace('');
    }
  }, [lostStolenModal]);

  async function changeCardLockStatus(status: any) {
    const { card } = props;
    return await new Promise((resolve, reject) => {
      let timeoutId: NodeJS.Timeout;

      const handleAfterClose = () => {
        clearTimeout(timeoutId);
        resolve(true);
      };

      timeoutId = setTimeout(() => {
        // resolve if execute not finished in 8 seconds
        resolve(true);
      }, 8000);

      LoadingHelper.executeAction(
        () => {
          return new CardModel().freezePaymentDevice(card.id, status);
        },
        {
          successMessage: status
            ? i18n.t('Your card is locked now')
            : i18n.t('Your card is active now'),
          loadingMessage: status
            ? i18n.t('Locking card...')
            : i18n.t('Unlocking card...'),
          failureMessage: i18n.t('An error ocurred, please try again later'),
          manualClose: false,
          renderIcon: (state: string, params: any) => {
            if (state === 'loading') return null;
            return status ? (
              <AppIcon name="fa.lock" style={{ fontSize: 150 }} />
            ) : (
              <AppIcon name="fa.unlock-alt" style={{ fontSize: 150 }} />
            );
          },
          beforeClose: () => {
            AuthService.refreshAuth();
          },
          afterClose: handleAfterClose
        }
      );
    });
  }

  function openLostStolenModal() {
    setLostStolenModal(true);
  }

  function closeLostStolenModal() {
    if (showRequestReplacementCardContent) {
      setShowRequestReplacementCardContent(false);
      setReasonForReplace('');
    } else {
      setLostStolenModal(false);
    }
  }

  return (
    <div
      className="row details-holder justify-content-center align-items-center"
      style={{ flexDirection: 'column' }}
    >
      <div className="top-line" />

      <div className="col-sm-4">
        <ul className="control-card">
          <li>
            {card?.locked ? i18n.t('Unlock card') : i18n.t('Lock Card')}
            <SwitchButton
              isChecked={!!card?.locked}
              onChange={(val: any) => changeCardLockStatus(val)}
            />
          </li>
        </ul>
      </div>
      <AppButton onClick={() => openLostStolenModal()}>
        <Trans>Report lost or stolen</Trans>
      </AppButton>
      <CardLostStolenModal
        show={lostStolenModal}
        onClose={() => setLostStolenModal(false)}
        card={card}
      />
    </div>
  );
};

export default LockCard;
