import {
  AppButton,
  AppIcon,
  LPOverridable,
  ModalDialog,
  SwitchButton
} from '@launchpad/components';
import i18n from '@launchpad/i18n';
import LoadingHelper from '@launchpad/util/LoadingHelper';
import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import CardModel from '../../models/CardModel';
import AuthService from '@launchpad/logic/services/AuthService';
import { OverrideService } from '@launchpad/logic/services';
import CardLostStolenReason from './CardLostStolenReason';
import CardLostStolenAddress from './CardLostStolenAddress';

const CardLostStolenModal = ({ show, onClose, card }) => {
  const [replaceStep, setReplaceStep] = useState(1);
  const [reasonForReplace, setReasonForReplace] = useState('');

  useEffect(() => {
    if (show) {
      setReplaceStep(1);
    }
  }, [show]);

  async function changeCardLockStatus(status: any) {
    return await new Promise((resolve, reject) => {
      let timeoutId: NodeJS.Timeout;

      const handleAfterClose = () => {
        clearTimeout(timeoutId);
        resolve(true);
      };
      timeoutId = setTimeout(() => {
        // resolve if execute not finished in 8 seconds
        resolve(true);
      }, 8000);
      LoadingHelper.executeAction(
        () => {
          return new CardModel().freezePaymentDevice(card.id, status);
        },
        {
          successMessage: status
            ? i18n.t('Your card is locked now')
            : i18n.t('Your card is active now'),
          loadingMessage: status
            ? i18n.t('Locking card...')
            : i18n.t('Unlocking card...'),
          failureMessage: i18n.t('An error ocurred, please try again later'),
          manualClose: false,
          renderIcon: (state: string, params: any) => {
            if (state === 'loading') return null;
            return status ? (
              <AppIcon name="fa.lock" style={{ fontSize: 150 }} />
            ) : (
              <AppIcon name="fa.unlock-alt" style={{ fontSize: 150 }} />
            );
          },
          beforeClose: () => {
            AuthService.refreshAuth();
          },
          afterClose: handleAfterClose
        }
      );
    });
  }

  return (
    <ModalDialog show={show} onClose={() => onClose()}>
      <div style={{ height: 600, display: 'flex' }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: 300,
            height: 600,
            backgroundColor:
              OverrideService.getThisOrOverridenColors().modal_background ||
              'white',
            borderRight: '1px solid #ccc'
          }}
        >
          <img
            src={OverrideService.getStyles().logo}
            alt="logo"
            width={'60%'}
          />
        </div>
        <div
          style={{
            display: 'flex',
            backgroundColor: 'white',
            padding: 20,
            position: 'relative',
            alignItems: 'center',
            justifyContent: 'center',
            maxWidth: 434
          }}
        >
          <div style={{ position: 'absolute', top: 10, right: 10 }}>
            <AppIcon
              name={'fa.times'}
              style={{ fontSize: 24, cursor: 'pointer' }}
              onClick={() => onClose()}
            />
          </div>

          {replaceStep === 1 ? (
            <div
              style={{ flex: 1, padding: 30 }}
              className="d-flex flex-column align-items-center justify-content-between text-center"
            >
              <h1 style={{ fontSize: 28 }}>
                <Trans>Was your card lost or {'\n'} stolen?</Trans>
              </h1>
              <p style={{ paddingTop: 20 }}>
                <Trans>
                  If so, please lock your card as soon {'\n'} as possible and
                  contact us on this number
                </Trans>
              </p>
              <div
                style={{
                  borderTop: '1px solid #ccc',
                  borderBottom: '1px solid #ccc',
                  padding: '15px 0',
                  width: '100%',
                  marginTop: 20,
                  marginBottom: 20
                }}
                className="d-flex align-items-center justify-content-between"
              >
                <p>
                  {card.locked ? i18n.t('Unlock card') : i18n.t('Lock Card')}
                </p>
                <SwitchButton
                  isChecked={!!card.locked}
                  onChange={(val: any) => changeCardLockStatus(val)}
                />
              </div>

              <div className="py-4">
                <AppButton onClick={() => setReplaceStep(2)}>
                  <Trans>REQUEST REPLACEMENT CARD</Trans>
                </AppButton>
              </div>
              <div className="d-flex align-items-center justify-content-center pr-3 call-phone-wrapper">
                <AppIcon
                  name="fa.phone"
                  style={{ fontSize: 20, marginRight: 5, color: 'black' }}
                />
                <LPOverridable name="card.page.lockModalNumber">
                  <a href="tel:0202 0202 0202" className="report-number">
                    <strong>(0202 0202 0202)</strong>
                  </a>
                </LPOverridable>
              </div>
            </div>
          ) : replaceStep === 2 ? (
            <CardLostStolenReason
              onBack={() => setReplaceStep(1)}
              onNext={reason => {
                setReasonForReplace(reason);
                setReplaceStep(3);
              }}
            />
          ) : (
            <CardLostStolenAddress
              onBack={() => setReplaceStep(2)}
              onNext={() => onClose()}
              changeCardLockStatus={changeCardLockStatus}
              card={card}
              reasonForReplace={reasonForReplace}
            />
          )}
        </div>
      </div>
    </ModalDialog>
  );
};

export default CardLostStolenModal;
