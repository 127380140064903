import React, { useState } from 'react';
import { AppIcon, OverrideService } from '@launchpad';
import CardService from '@launchpad/modules/card/CardService';
import { useSelector } from 'react-redux';
import i18n from '@launchpad/i18n';

const RotateCard = (props: any) => {
  const [isFlipped, setIsFlipped] = useState(false);

  const paymentDevices = useSelector((state: any) => state.paymentDevices);

  const customFlipOnClick = () => {
    let paymentDevice = CardService.getDefaultCard(paymentDevices);
    if (paymentDevices.length > 1) {
      paymentDevice = props.card;
    }
    const cardActive = paymentDevice.isLive;

    if (cardActive && paymentDevice.status !== 'locked') {
      setIsFlipped(!isFlipped);
    }
  };

  let paymentDevice = CardService.getDefaultCard(paymentDevices);
  if (paymentDevices.length > 1) {
    paymentDevice = props.card;
  }

  if (!paymentDevice) return null;

  const cardActive = paymentDevice.isLive;
  const { frontCard, backCard } = props || OverrideService.getStyles();

  return (
    <div className="flip-card-holder">
      <div
        className="send-load-btn-wrapper rotate-btn"
        style={{
          opacity: cardActive && paymentDevice.status !== 'locked' ? 1 : 0.3
        }}
      >
        <div className="send-load-icon-wrapper" onClick={customFlipOnClick}>
          <AppIcon name="fa.refresh" style={{ fontSize: 24 }} />
        </div>
      </div>
      <div
        className={`flip-card ${isFlipped ? 'flipped' : ''}`}
        onClick={customFlipOnClick}
      >
        <div className="front">
          <img
            src={frontCard || require('../../assets/card.png')}
            alt="card"
            className="card-page-card-image"
          />
        </div>
        <div className="back">
          <p className="card-pan">{i18n.t(paymentDevice.pan)}</p>
          <p
            className="card-expires"
            style={{
              right: OverrideService.getOverrides()['expires-right'] || '70px',
              bottom: OverrideService.getOverrides()['expires-bottom'] || '80px'
            }}
          >
            {i18n.t(paymentDevice.expires)}
          </p>
          <img
            src={backCard || require('../../assets/card.png')}
            alt="card"
            className="card-page-card-image"
          />
        </div>
      </div>
    </div>
  );
};

export default RotateCard;
